<template>
  <v-app light>
    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      disable-route-watcher
      width="220"
    >
      <v-list>
        <v-list-item
          router
          :to="item.to"
          :key="i"
          v-for="(item, i) in menuItems"
          exact
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <tool-bar
      :drawer="drawer"
      @toggleDrawer="drawer = !drawer"
    />
    <v-main
      transition="slide-x-transition"
    >
      <slot/>
    </v-main>
  </v-app>
</template>

<script>
  import ToolBar from '@/components/ToolBar'

  export default {
    components: {
      ToolBar
    },
    data () {
      return {
        drawer: null,
        selectedDate: null
      }
    },
    computed: {
      menuItems() {
          return [
            {title: this.$t('navigation.home'), to: '/'},
            {title: this.$t('navigation.home_visits'), to: '/huisbezoeken'},
            {title: this.$t('navigation.doctor_on_duty'), to: '/wachtdiensten'},
            {title: this.$t('navigation.emergency_numbers'), to: '/noodnummers'},
            {title: this.$t('navigation.global_medical_record'), to: '/gmd'},
            {title: this.$t('navigation.vaccination'), to: '/griepvaccinatie'},
            {title: this.$t('navigation.links'), to: '/links'},
            {title: 'Contact', to: '/contact'}
          ]

      }
    }
  }
</script>
