<template>
  <v-app-bar
    fixed
    app
    dark
    color="primary"
  >
    <v-app-bar-nav-icon
      @click.stop="toggleDrawer"
    />
    <v-toolbar-title :class="[$vuetify.breakpoint.mobile ? 'text-body-1' : '']">
      Huisartsenpraktijk Robyns
    </v-toolbar-title>
    <v-spacer/>
    <LocaleDropdown/>
  </v-app-bar>
</template>

<script>

import LocaleDropdown from './LocaleDropdown';

export default {
  components: {LocaleDropdown},
  props: {
    drawer: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    appName: window.config.appName,
    busy: false
  }),
  methods: {
    toggleDrawer () {
      this.$emit('toggleDrawer')
    },
    async logout () {
      this.busy = true

      if (this.drawer) {
        this.toggleDrawer()
      }

      // Log out the user.
      await this.$store.dispatch('auth/logout')
      this.busy = false

      // Redirect to login.
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
